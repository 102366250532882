import React from 'react'
import styled from 'styled-components'
import { range, max } from 'd3-array'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import ReactTooltip from 'react-tooltip'
import { VACCINE_LABELS_IG22_API } from '../../utils/const'

const Container = styled.div`
  flex: 1;
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: 300px;
  height: 500px;
  margin: 0 2rem 7rem 1rem;
  display: flex;
`
const GridContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin-top: 1rem;
`
const GridLine = styled.div`
  border-bottom: ${({ boldTick }) =>
    boldTick ? '3px solid var(--darkBlue)' : '1px solid var(--lightGray)'};
  height: 2px;
  width: 100%;
`
const Flex = styled.div`
  display: flex;
  align-items: center;
`
const Tick = styled.div`
  font-size: 1rem;
  color: var(--gray);
  padding-right: 0.5rem;
  width: 2.5rem;
  text-align: right;
  ${Mq.md} {
    font-size: 0.9rem;
  }
`
const BarContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 20px;
  height: calc(100% - 1.3rem);
  width: calc(100% - 20px);
  // opacity: 0.5;
  // background-color: pink;
  display: flex;
`
const BarColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  max-width: 80px;
  margin: 0 0 0 40px;
  .tooltip {
    //font-weight: bold;
    font-size: 1.1rem;
    color: var(--white);
    background-color: var(--darkOrange);
  }
`
const BarSegment = styled.div`
  height: ${({ height }) => height};
  background-color: var(--brightBlue);
  border-bottom: 1px solid var(--white);
  &:hover {
    border: none;
    outline: 4px solid var(--darkOrange);
    z-index: 5;
  }
`
const BarLabel = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ total }) => (total ? 'bold' : 'normal')};
  color: ${({ total }) => (total ? 'var(--brightBlue)' : 'var(--darkGray)')};
  text-align: center;
  position: absolute;
  width: 20%;
  max-width: 80px;
  bottom: ${({ position }) => position};
  ${Mq.md} {
    font-size: 1rem;
  }
`
const LineLabels = styled.div`
  width: 122px;
  height: ${({ height }) => height};
  position: absolute;
  right: 0;
  bottom: 0;
  //border-top: 3px solid var(--darkBlue);
`
const Label = styled.div`
  position: absolute;
  top: ${({ position }) => position};
  right: 0;
  width: 100%;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.3;
  color: var(--baseBlue);
  text-align: right;
  padding-right: 0.5rem;
  ${Mq.sm} {
    font-size: 0.9rem;
  }
`
const Line = styled.div`
  width: calc(100% - 2rem);
  height: 3px;
  background-color: var(--darkBlue);
  position: absolute;
  right: 0;
  bottom: ${({ height }) => height};
  z-index: 5;
`
// const Asterik = styled.div`
//   position: absolute;
//   bottom: -80px;
//   left: 0;
//   font-size: 1.1rem;
//   ${Mq.md} {
//     font-size: 1rem;
//   }
// `
const IG22Chart = ({ formattedData, regional }) => {
  const { t } = useTranslation()
  const maxValue = max(formattedData.map((item) => item[0].VALUE_NUM))
  const ticks = range(0, Math.ceil(maxValue / 20 + 1))
  const calcHeight = (num) => {
    return (num / ((ticks.length - 1) * 20)) * 100
  }
  const calcMaxWidth = (data) => {
    return Math.max(data.length * 100 + 100, 530) + 'px'
  }

  return (
    <Container
      maxWidth={calcMaxWidth(formattedData)}
      role="img"
      alt={t(
        'Bar chart showing number of new or under-utilized vaccines in low and middle income countries by year.'
      )}
    >
      <GridContainer>
        {ticks.reverse().map((tick, i) => (
          <Flex key={tick}>
            <Tick>{tick * 20}</Tick>{' '}
            {/* <GridLine boldTick={!regional && tick === 5} /> */}
            <GridLine />
          </Flex>
        ))}
        {/* 51% to take height of line into account */}
        {!regional && <Line height={`${calcHeight(51.0)}%`} />}
        <BarContainer>
          {formattedData.map((year) => (
            <BarColumn key={year[0].YEAR}>
              <BarLabel
                total={true}
                position={`${calcHeight(year[0].VALUE_NUM)}%`}
              >
                {year[0].VALUE_NUM}
              </BarLabel>
              <ReactTooltip
                place={'right'}
                //type={'dark'}
                arrowColor={'#d96038'}
                effect={'solid'}
                className="tooltip"
              />
              {year.map(
                (d) =>
                  d.DIMENSION2 !== null && (
                    <BarSegment
                      height={`${calcHeight(d.VALUE_NUM)}%`}
                      key={d.YEAR + d.DIMENSION2}
                      data-tip={
                        d.VALUE_NUM +
                        ' ' +
                        `${t(VACCINE_LABELS_IG22_API[d.DIMENSION2])}` +
                        ' ' +
                        `${t('introductions')}`
                      }
                    ></BarSegment>
                  )
              )}

              <BarLabel position={'-40px'}>{year[0].YEAR}</BarLabel>
            </BarColumn>
          ))}
          {!regional && (
            <>
              <LineLabels height={`${calcHeight(50.0)}%`}>
                <Label position={'-30px'}>{t('2030 target')}</Label>
                <Label position={'5px'}>
                  {t('average of 50 annual introductions')}
                </Label>
              </LineLabels>
            </>
          )}
          {/* <Asterik>
            <sup>*</sup>Baseline = average across 2011-2020
          </Asterik> */}
        </BarContainer>
      </GridContainer>
    </Container>
  )
}

export default IG22Chart
