import React from 'react'
import Table from '../Table'
import { VACCINE_LABELS_IG22_API } from '../../utils/const'
import { useTranslation } from 'react-i18next'
import { group } from 'd3-array'

const IG22Table = ({ regions }) => {
  const { t } = useTranslation()
  let years
  // const wrangleData = () => {
  //   const allYears = []
  //   const allVaccines = []

  //   const allData = Object.entries(regions)
  //     .map(([key, value]) => {
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]
  //       if (!keysToExclude.includes(key)) {
  //         const year = +key.slice(-4)
  //         const vaccine = key.slice(0, -4)
  //         allVaccines.push(vaccine)
  //         if (value) allYears.push(year)
  //         return { year, vaccine, value }
  //       } else return null
  //     })
  //     .filter((item) => item)

  //   const vaccines = Array.from(new Set(allVaccines))
  //   years = Array.from(new Set(allYears))

  //   const formattedData = vaccines.map((vaccine) => {
  //     const vacData = allData
  //       .filter((d) => d.vaccine === vaccine)
  //       .reduce((acc, d) => {
  //         acc[d.year] = d.value >= 0 ? d.value : t('no data')
  //         return acc
  //       }, {})
  //     return Object.assign(
  //       {
  //         vaccine: t(VACCINE_LABELS_IG22[vaccine])
  //       },
  //       vacData
  //     )
  //   })
  //   return formattedData
  // }
  const wrangleData = (data) => {
    const allYears = []
    data.forEach((d) => {
      allYears.push(d.YEAR)
      if (d.DIMENSION2 === null) d.vaccine = 'Total'
      else d.vaccine = d.DIMENSION2
    })
    const dataMap = group(data, (d) => d.vaccine)
    const arr = Array.from(dataMap.values())

    const configured = arr.map((a) =>
      a.reduce((acc, d) => {
        acc[d.YEAR] = d.VALUE_NUM
        acc['vaccine'] = t(VACCINE_LABELS_IG22_API[d.vaccine])
        return acc
      }, {})
    )
    years = Array.from(new Set(allYears))
    //if all years not included in each row for each vaccine, add them with a zero value.
    configured.forEach((c) => {
      years.forEach((y) => {
        return c.hasOwnProperty(y) ? c : (c[y] = 0)
      })
    })
    return configured
  }
  const ig22Data = wrangleData(regions)
  //const ig22Data = wrangleData()
  const data = React.useMemo(() => ig22Data, [ig22Data])

  const yearHeaders = years.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })

  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: t('vaccine introductions'),
        accessor: 'vaccine' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])

  return (
    <Table
      data={data}
      columns={columns}
      description={t(
        'A table that shows the number of vaccine introductions each year.'
      )}
    />
  )
}

export default IG22Table
