import * as React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import Callout from '../Callout'
import IG22Chart from './IG22Chart'
import { NUM_YEARS } from '../../utils/const'
//import { wrangle22Data } from '../../utils/helpers'
import ImageExport from '../ImageExport'
import { group } from 'd3-array'

const Flex = styled.div`
  display: flex;
  justify-content: start;
  //align-items: center;
  margin: 2rem 0;
  flex-wrap: wrap;
`

const Container = styled.div`
  margin-bottom: 4rem;
  ${Mq.md} {
    margin-bottom: 9rem;
  }
`
//using a wrapper so data wrangling can happen here and be passed down to both Chart and Callout
const IG22ChartWrapper = ({ regions, content, sectionId, regional, title }) => {
  //const formattedData = wrangle22Data(regions)
  const dataMap = group(regions, (d) => d.YEAR)
  const formattedData = Array.from(dataMap.values())

  // const total = formattedData
  //   .map((array) => {
  //     return array.find((d) => d.vaccine === 'vaccineIntro')
  //   })
  //   .reduce((sum, d) => {
  //     //take out baseline data from total
  //     sum += d.year === 2020 ? 0 : d.value
  //     return sum
  //   }, 0)
  const total = formattedData
    .map((array) => {
      return array.find((d) => d.DIMENSION2 === null) // this is the total row
    })
    .reduce((sum, d) => {
      //take out baseline data from total
      sum += d.YEAR === 2020 ? 0 : d.VALUE_NUM
      return sum
    }, 0)

  //don't count first year in number of elapsed years
  const numYears = formattedData.length - 1

  const goal = 500
  const goalPerYear = goal / NUM_YEARS
  const totalNeededToBeOnTrack = numYears * goalPerYear
  const onTarget = total >= totalNeededToBeOnTrack

  const matches = useMediaQuery({ query: '(max-width: 900px)' })
  const currYear = formattedData.length
    ? formattedData[formattedData.length - 1][0].YEAR
    : 0

  return (
    <>
      <ImageExport title={title} dataSource={content.dataSourceNotesRt}>
        <Flex>
          <IG22Chart formattedData={formattedData} regional={regional} />

          <Callout
            content={content}
            sectionId={sectionId}
            data={total}
            onTarget={onTarget}
            regional={regional}
            currYear={currYear}
          />
        </Flex>
      </ImageExport>
      <Container>
        <Flex>
          <IG22Chart formattedData={formattedData} regional={regional} />
          {!matches ? (
            <Callout
              content={content}
              sectionId={sectionId}
              data={total}
              onTarget={onTarget}
              regional={regional}
              currYear={currYear}
            />
          ) : null}
        </Flex>
        {matches ? (
          <Callout
            content={content}
            sectionId={sectionId}
            data={total}
            onTarget={onTarget}
            regional={regional}
            currYear={currYear}
          />
        ) : null}
      </Container>
    </>
  )
}

export default IG22ChartWrapper
